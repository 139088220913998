.App {
  font-family: sans-serif;
  text-align: left;
  display: flex;
  flex: 1;
  flex-direction: column;
}

html {
  background: linear-gradient(
      45deg,
      rgba(29, 224, 153, 0.8),
      rgba(29, 200, 205, 0.8)
    ),
    url("https://udok.com.br/img/intro-bg.jpg") center top no-repeat;
  flex: 1;
  display: flex;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  flex: 1;
}

#root {
  flex: 1;
  display: flex;
}

#container {
  margin: 12px;
  border-radius: 16px;
  background: #171717;
  overflow: hidden;
  flex: 1;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
}

.App.in-call #container,
.App.in-call.ios #container {
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.App.ios #container {
  border-radius: 16px 16px 0 0;
}

.App #container video.local-stream,
.App #container video.remote-stream {
  width: 100%;
}

.App #container video.remote-stream {
  display: none;
}

.App.in-call #container video.remote-stream {
  display: block;
}

.App.in-call #container video.local-stream {
  position: absolute;
  right: 10px;
  bottom: 58px;
  width: 100px;
  height: auto;
  border-radius: 6px;
  overflow: hidden;
}

.blurred {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
}

.box {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 16px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box::after {
  content: "";
  border-radius: 16px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.57);
}

.box:active::after {
  animation: anim-effect-boris 0.5s forwards;
}

@keyframes anim-effect-boris {
  0% {
    opacity: 0.1;
  }
  25%,
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.App .avatar img {
  overflow: hidden;
  border-radius: 100px;
}
.App .avatar {
  display: block;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.App.in-call .bottomBar .avatar {
  display: none;
}

.App.in-call .bottomBar .username {
  margin-left: 0 !important;
}

.App .enter-room-btn {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.App.in-call .enter-room-btn {
  display: none;
}

.App .recording-controls {
  display: none;
  width: 0;
  opacity: 0;
  flex: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  max-width: 150px;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.App.in-call .recording-controls {
  width: auto;
  opacity: 1;
  display: flex;
  flex: 1;
}

.App .bottomBar {
  z-index: 10;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  margin: -48px 12px 12px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.App.in-call .bottomBar {
  position: relative;
  padding: 0 12px;
  margin: -48px 0 0;
  border-radius: 16px 16px 0 0;
  z-index: 9999;
}

.App.ios .bottomBar {
  margin: -12px 12px 12px;
  border-radius: 0 0 16px 16px;
}

.App.ios.in-call .bottomBar {
  margin: 0;
  border-radius: 0;
}

.App .bottomBar .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px;
}

.App.in-call .bottomBar .title {
  margin: 0;
}

.App header,
.App .page-title {
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.App header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.App footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.App footer img {
  flex: 1;
  max-height: 30px;
  max-width: 75px;
  margin: 12px;
}

.App .page-title {
  color: #fff;
  margin: 12px;
}

.App.in-call header,
.App.in-call .page-title {
  display: none;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.App .btn {
  padding: 15px 24px;
  border-radius: 6px;
  border: none;
  background: #fff;
  color: #008e8d;
  font-size: 18px;
}

.App .peer-placeholder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
}

.App .peer-placeholder .title {
  background-color: #fff;
  padding: 24px;
  border-radius: 24px;
}
.App .peer-placeholder .card {
  background: linear-gradient(
    45deg,
    rgba(29, 224, 153, 0.8),
    rgba(29, 200, 205, 0.8)
  );
  border-radius: 24px;
  padding: 24px;
}

.App.in-call .peer-placeholder {
  display: flex;
}

.App .title {
  margin: 12px;
  font-family: sans-serif;
  font-size: 24px;
  text-align: left;
}

@media only screen and (min-width: 600px) {
  .App #container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App #container #peerVideo {
    max-height: 80vh;
  }

  .App #container #localVideo {
    width: 250px;
  }
}
